import React, { useState } from "react";
import { FormWrapper } from '@decisionm/react-gen-lib'

interface FromWidgetProps {
    args?: any
}

const FromWidget: React.FC<FromWidgetProps> = (props) => {
    const args: any = props
    const [formData, setFormData] = useState()

    const handleFormChange = (data: any) => {
        setFormData(data);
    };

    const submit = () => {
        args.payload.func(formData)
    }
    return (
        <div>
            <FormWrapper onChange={handleFormChange} {...args} />
            <button onClick={submit}>submit</button>
        </div>
    )
}

export default FromWidget;