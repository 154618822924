import React from "react";
import * as ReactDOMClient from 'react-dom/client';
import ComponentsRenderer from "../components";
export const renderReactComponent = (id: any, payload: any, data: any): void => {
    console.log('Angular Props',typeof(id), payload, data)
    const container = document.getElementById(id);
    if (!container) {
        console.error(`Element with ID ${id} not found.`);
        return;
    }
    const root = ReactDOMClient.createRoot(container as HTMLElement);

    root.render(
        <React.Fragment >
            <ComponentsRenderer pageId={id} payload={payload} data={data}/>
        </React.Fragment>
    )
}