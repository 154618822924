import React from 'react'
import {DataGrid} from '@decisionm/react-gen-lib'
import { render } from '@testing-library/react'

interface TableProps{
column?:any
data?:any
downloadName?:any
}

const GridTable:React.FC<TableProps> = (props) => {
  const {column,data,downloadName}=props

  console.log('qqq-9',props)

  let tableData=data|| [
    { id: 1, name: true, age: 25,linkPermission:true },
    { id: 2, name: false, age: 30 },
    { id: 1, name: 'false', age: 25 },
    { id: 2, name: 'true', age: 30 },
    { id: 1, name: 'Alice', age: 25 },
    { id: 2, name: 'Bob', age: 30 },
    { id: 1, name: 'Alice', age: 25 },
    { id: 2, name: 'Bob', age: 30 },
    { id: 1, name: 'Alice', age: 25 },
    { id: 2, name: 'Bob', age: 30 },
    { id: 1, name: 'Alice', age: 25 },
    { id: 2, name: 'Bob', age: 30 },
    { id: 1, name: 'Alice', age: 25 },
    { id: 2, name: 'Bob', age: 30 },
    { id: 1, name: 'Alice', age: 25 },
    { id: 2, name: 'Bob', age: 30 },
    { id: 1, name: 'Alice', age: 25 },
    { id: 2, name: 'Bob', age: 30 },
    { id: 1, name: 'Alice', age: 25 },
    { id: 2, name: 'Bob', age: 30 },
    { id: 1, name: 'Alice', age: 25 },
    { id: 2, name: 'Bob', age: 30 },
    { id: 1, name: 'Alice', age: 25 },
    { id: 2, name: 'Bob', age: 30 },
    
    // ... more mock data
  ]

  const a=(value:any,column:any,index:any)=>{
    console.log('qqq-5',value,column,index)
    alert('guna')
  }

  let tableColumn =column?.map((value:any)=>{
   return{...value}
  })||[
       { title: 'ID', name: 'id',total:false },
      { title: 'Name', name: 'name',filter:true,editable:false,link:a},
      { title: 'Age', name: 'age',sorting:'true',filter:true,total:'avg',currency:'USD' },
      // ... more columns if needed
     ]
  
  return (
    <DataGrid
    data={tableData}
    column={tableColumn}
    // multiSelect={()=>{}}
    pagination={true}
    rowsPerPage={true}
    rowsCount={true}
    goTo={false}
    pagesCount={false}
    itemsPerPage={8}
    itemsSizeOptions={[]}
    dragable={true}
    download={
      {
      type:['csv','pdf'],
      name:downloadName||'Report'
      }
    }
    grouping={'name'}
    />
  )
}

export default GridTable