import React from 'react';
import {Charts} from '@decisionm/react-gen-lib'
interface ChartProps {
    type:any,
    color:any,
    data:any,
    x_axis_config:any
    y_axis_config:any
    showValue:boolean
    style?:any
}

const Chart: React.FC<ChartProps> = (props) => {
    const {type="bar",color,data,showValue,style={}}=props
    

    console.log('qqq-4',props,type)
 
   let colors= ['rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)',]
    
    let labels=data?.labels|| ['January', 'February', 'March', 'April', 'May']
   let chartData=data|| {
    "labels": [
        "Jan",
        "Feb",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Sep",
        "Oct"
    ],
    "datasets": [
        {
            "data": [
                "50",
                "70",
                "53",
                "25",
                "85",
                "20",
                "60",
                "90"    
            ],
            "type": "line",
            "label": "Data Set Label",
            "backgroundColor": "#78ff00",
        },
        {
            "data": [
                "55",
                "79",
                "83",
                "35",
                "87",
                "40",
                "30",
                "20"
            ],
            "type": "bar",
            "label": "Month",
            "backgroundColor": "#29b5e8"
        }
    ]
}
   let xAxisProp=props?.x_axis_config
   let yAxisProp=props?.y_axis_config
   let chartProperties=data?.datasets?.at(0)


let newChartData={
    labels:labels,
    datasets:chartData?.datasets&&chartData?.datasets?.map((data:any,key:any)=>{
    console.log('qqq-5',data?.type)
    if(chartData?.datasets?.length==1){
    return {
        ...data,
        ["type"]:type,
        borderWidth:type=="line"?7:2,
        backgroundColor:type=='pie'?color?.brewer:data?.backgroundColor,
    }
}else{
    return{...data}
}
})
}
    return (
           <Charts
           style={style}
            type={type||'bar'}
            gridy={false}
            gridx={false}
            title={""}
            legend={[chartProperties?.label]}
            variant={'rounded'}
            colors={[chartProperties?.backgroundColor]||[colors?.at(0)]}
            labels={newChartData?.labels}
            data={newChartData}
            showValue={showValue}
            xAxisProp={{...xAxisProp}||{
            name:'hello',
            postfix:'&',
            prefix:'#',
             }}
        yAxisProp={{...yAxisProp}||{
            name:'hello',
            postfix:'&',
            prefix:'#',
            number_format:'comma',
        } } 
           />
    );
};

export default Chart;
